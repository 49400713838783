import Vue from 'vue';
import App from './App.vue';
import VueRouter from 'vue-router';
import device from 'vue-device-detector-js';

//Роуты
import Home from './pages/Home/Home';

Vue.config.productionTip = false;
Vue.use(VueRouter);
Vue.use(device);

const CatApplication = () => import('./pages/CatApplication/CatApplication');

const routes = [
  { path: '/', component: Home, name: 'home' },
  { path: '/cat_applications/:id', component: CatApplication, name: 'cat_aplications' },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
