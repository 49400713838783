<template>
  <div class="overlay">
    <div class="modal">
      <div :class="['modal__body', { 'modal__body--form': forForm }]">
        <div class="modal__close" @click="handleModalCloseClick"></div>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { eventBus, customEvents } from '@/event-bus';

export default {
  name: 'Frrmodal',
  props: {
    forForm: Boolean,
  },
  mounted() {
    document.querySelector('body').style.overflow = 'hidden';
  },
  beforeDestroy() {
    document.querySelector('body').style.overflow = 'inherit';
  },
  methods: {
    handleModalCloseClick() {
      eventBus.$emit(customEvents.HIDE_MODAL);
      this.$emit('close');
    },
  },
};
</script>

<style scoped lang="sass">
.overlay
  position: fixed
  top: 0
  right: 0
  bottom: 0
  left: 0
  background-color: rgba(0, 0, 0, 0.35)
  overflow-y: auto
  opacity: 1
  z-index: 700

.modal
  width: 100%
  min-height: 100%
  display: flex
  justify-content: center
  align-items: center
  padding: 5px 20px

.modal__body
  position: relative
  flex-grow: 1
  max-width: 767px
  padding: 40px 0
  background-color: #5228C5
  border: 4px solid $bg-color-pink-light
  border-radius: 45px

.modal__body--form
  max-width: 554px
  width: 100%

.modal__close
  position: absolute
  cursor: pointer
  top: -9px
  right: -20px
  width: 53px
  height: 53px
  border: 3px solid $bg-color-pink-light
  border-radius: 50%
  background: url('../../assets/img/icons/close-icon.svg') center no-repeat, $bg-color-violet
  background-size: 24px 24px
  transition: box-shadow 200ms linear

  &:active
    box-shadow: inset 0px 4px 15px rgba(0, 0, 0, 0.35)
</style>
