<template>
  <div class="home">
    <section class="home__stream">
      <FrrStreamVk />
    </section>

    <section class="home__commercial">
      <div class="commerсial">
        <div class="commercial__btn-wrap">
          <FrrRoundBtn type="tiktok" href="https://www.tiktok.com/@frrfrrhouse" size="normal" />
        </div>
        <div class="commercial__btn-wrap commercial__btn-wrap--dsm">
          <FrrRoundBtn type="tiktok" href="https://www.tiktok.com/@frrfrrhouse" size="normal" />
        </div>
        <!-- <FrrAdvertising /> -->
        <div class="commercial__banner-wrapper">
          <img class="commercial__banner" src="../../assets/img/banners/banner-cat.png" alt="pretty kitty" />
        </div>
        <p class="commercial__text">Вместо этого котика могла&nbsp;быть ваша реклама!</p>
      </div>
    </section>

    <section class="home__profiles">
      <FrrProfiles />
    </section>

    <div class="home__slogan">
      <FrrDescription>
        <div class="home__slogan-text">
          Наши участники попадают в TikTok House только из приюта, а это значит, что у каждого из вас есть уникальная
          возможность стать обладателем пушистого селеба с целой армией поклонников.
        </div>
      </FrrDescription>
    </div>

    <section class="home__commercial home__commercial--bottom">
      <div class="commerсial commerсial--bottom">
        <div class="commercial__btn-wrap commercial__btn-wrap--bottom">
          <FrrRoundBtn type="tiktok" href="https://www.tiktok.com/@frrfrrhouse" size="large" />
        </div>
        <div class="commercial__banner-wrapper commercial__banner-wrapper--bottom">
          <img class="commercial__banner" src="../../assets/img/banners/banner-planet.png" alt="frrfrr" />
        </div>
        <p class="commercial__text">Вместо этой планеты могла&nbsp;быть ваша реклама!</p>
      </div>
    </section>
    <div class="home__outro">
      <FrrOutro />
    </div>
    <FrrModal v-if="isModalShown" :forForm="!!selectedProfile">
      <FrrHowTo v-if="!selectedProfile" />
      <FrrForm :profiles="profiles" :selectedProfile="selectedProfile" v-else />
    </FrrModal>
  </div>
</template>

<script>
import FrrStreamVk from '@/components/FrrStreamVk/FrrStreamVk.vue';
import FrrProfiles from '@/components/FrrProfiles/FrrProfiles.vue';
import FrrRoundBtn from '@/components/FrrRoundBtn/FrrRoundBtn.vue';
import FrrDescription from '@/components/FrrDescription/FrrDescription.vue';
import FrrOutro from '@/components/FrrOutro/FrrOutro.vue';
import FrrModal from '@/components/FrrModal/FrrModal';
import FrrHowTo from '@/components/FrrHowTo/FrrHowTo';
import FrrForm from '@/components/FrrForm/FrrForm';
import { eventBus, customEvents } from '@/event-bus';
import { getProfiles } from '@/api';

export default {
  name: 'Home',
  components: {
    FrrStreamVk,
    FrrProfiles,
    FrrRoundBtn,
    FrrDescription,
    FrrOutro,
    FrrModal,
    FrrHowTo,
    FrrForm,
  },
  data: () => ({
    profiles: [],
    isModalShown: false,
    selectedProfile: null,
  }),
  created() {
    this.getProfiles();
    eventBus.$on(customEvents.SHOW_MODAL, (profile) => this.handleShowModal(profile));
    eventBus.$on(customEvents.HIDE_MODAL, () => this.handleCloseModal());
  },
  methods: {
    handleShowModal(profile) {
      this.isModalShown = true;
      this.selectedProfile = profile;
    },
    handleCloseModal() {
      this.isModalShown = false;
      document.querySelector('body').style.overflow = 'auto';
      this.selectedProfile = null;
    },
    getProfiles() {
      getProfiles()
        .then((data) => {
          eventBus.$emit(customEvents.PROFILES_LOADED, data);
          this.profiles = data;
        })
        .catch((err) => console.error(err));
    },
  },
};
</script>

<style scoped src="./Home.sass" lang="sass"></style>
