<template>
  <div>
    <div class="frr">
      <div class="frr__container" ref="containerRef">
        <div class="frr__ticker frr__ticker--top">
          <FrrTicker position="top" />
        </div>
        <div class="frr__ticker frr__ticker--right" :style="{ height: containerHeight }">
          <FrrTicker position="right" />
        </div>
        <div class="frr__ticker frr__ticker--bottom">
          <FrrTicker position="bottom" />
        </div>
        <div class="frr__ticker frr__ticker--left" :style="{ height: containerHeight }">
          <FrrTicker position="left" />
        </div>
        <div class="frr__content">
          <section class="frr__intro">
            <FrrIntro />
          </section>
          <router-view></router-view>
          <div class="frr__scroll-wrapper" v-show="isHome">
            <div
              class="frr__scroll-top"
              id="scroll-top"
              v-show="isScrollTopShow"
              @mouseup="scrollTop"
              @touchend="scrollTop"
              ref="scrollTopBtnRef"
              :key="scrollButtonKey"
            >
              <FrrScrollTop />
            </div>
          </div>
        </div>
        <div ref="bottomContentRef"></div>
      </div>
      <FrrFooter />
    </div>
  </div>
</template>

<script>
import FrrTicker from '@/components/FrrTicker/FrrTicker.vue';
import FrrScrollTop from '@/components/FrrScrollTop/FrrScrollTop.vue';
import FrrIntro from '@/components/FrrIntro/FrrIntro.vue';
import FrrFooter from '@/components/FrrFooter/FrrFooter.vue';
import Polyfill from 'resize-observer-polyfill';
import { eventBus, customEvents } from '@/event-bus';

const ResizeObserver = window.ResizeObserver || Polyfill;

export default {
  name: 'App',
  components: {
    FrrTicker,
    FrrScrollTop,
    FrrIntro,
    FrrFooter,
  },
  data: () => ({
    containerHeight: '',
    isScrollTopShow: false,
    isModalOpen: false,
    resizeObserver: null,
    isFixedVideoVisible: false,
    scrollButtonKey: Date.now(),
  }),
  computed: {
    isHome() {
      return this.$router.currentRoute.name == 'home';
    },
  },
  mounted() {
    this.setContainerHeight();
    this.subOnScroll();
    this.subOnResizeContainer();
    this.handleCustomEvents();
    this.addStyleForFirefox();
  },
  beforeDestroy() {
    this.unsubFromResizeContainer();
    this.unsubFromScroll();
  },
  methods: {
    addStyleForFirefox() {
      this.detectMobileFirefox() && (document.documentElement.style.scrollBehavior = 'smooth');
    },
    handleCustomEvents() {
      eventBus.$on(customEvents.SHOW_MODAL, () => {
        this.isScrollTopShow = false;
        this.isModalOpen = true;
        this.changeScrollButtonKey();
      });
      eventBus.$on(customEvents.HIDE_MODAL, () => {
        this.isModalOpen = false;
        this.handleScrollTopBtnVisibility();
      });
      eventBus.$on(customEvents.TOGGLE_FIXED_VIDEO_VISIBILITY, (flag) => {
        this.isFixedVideoVisible = flag;
        this.handleScrollTopBtnVisibility();
      });
    },
    setContainerHeight() {
      const delta = window.innerWidth < 1024 ? 25 : 38;
      this.containerHeight = this.$refs.containerRef.getBoundingClientRect().height - delta + 'px';
    },
    changeScrollButtonKey() {
      this.scrollButtonKey = Date.now();
    },
    scrollTop() {
      if (this.detectMobileFirefox()) {
        window.scroll(0, 0);
      } else {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      }
      setTimeout(() => this.changeScrollButtonKey(), 200);
    },
    detectMobileFirefox() {
      return this.$device.isAndroid && !Object.values(this.$device.browser).includes(true);
    },
    handleScrollTopBtnVisibility() {
      if (!this.$refs.bottomContentRef) return;

      const MOBILE_BP = 1024;
      const { top, bottom } = this.$refs.bottomContentRef.getBoundingClientRect();
      const button = this.$refs.scrollTopBtnRef;

      this.isScrollTopShow = !this.isModalOpen && window.scrollY > window.innerHeight;
      if (window.innerWidth > MOBILE_BP) {
        const videoPadding = 30;

        let deltaWithVideo = this.isFixedVideoVisible ? 353 : 50;
        if (top > 0 && top < window.innerHeight) {
          deltaWithVideo += window.innerHeight - bottom + videoPadding;
          eventBus.$emit(customEvents.FOOTER_IN_VUEWPORT, window.innerHeight - bottom + videoPadding);
        } else {
          eventBus.$emit(customEvents.FOOTER_IN_VUEWPORT, 0);
        }
        button.style.transform = `translateY(-${deltaWithVideo}px)`;
      }
    },
    subOnScroll() {
      this.handleScrollTopBtnVisibility();
      window.addEventListener('scroll', this.handleScrollTopBtnVisibility, { passive: true });
    },
    unsubFromScroll() {
      window.removeEventListener('scroll', this.handleScrollTopBtnVisibility);
    },
    subOnResizeContainer() {
      this.resizeObserver = new ResizeObserver(() => {
        this.setContainerHeight();
      });
      this.resizeObserver.observe(this.$refs.containerRef);
    },
    unsubFromResizeContainer() {
      this.resizeObserver.unobserve(this.$refs.containerRef);
      this.resizeObserver = null;
    },
  },
};
</script>

<style lang="sass">
@import '~swiper/css/swiper.css'
@import '~swiper/components/effect-fade/effect-fade.scss'
@import './assets/sass/root'
</style>

<style lang="sass" scoped src="./App.sass"></style>
