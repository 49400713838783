<template>
  <div class="outro">
    <div class="outro__bg">
      <div class="outro__logo">
        <img src="../../assets/img/logos/logo.png" alt="FrrFrr" class="outro__logo-img" />
      </div>
    </div>

    <div class="outro__animated outro__animated__color outro__lasers">
      <img src="../../assets/img/animated/lasers-2.png" alt="FrrFrr" class="outro__img" />
    </div>
    <div class="outro__animated outro__animated__color outro__lasers outro__lasers--reverse">
      <img src="../../assets/img/animated/lasers-2.png" alt="FrrFrr" class="outro__img" />
    </div>
    <div class="outro__animated outro__animated__color outro__shine outro__shine--left">
      <img src="../../assets/img/animated/shine-violet.png" alt="FrrFrr" class="outro__img" />
    </div>
    <div class="outro__animated outro__animated__color outro__shine outro__shine--right">
      <img src="../../assets/img/animated/shine-green.png" alt="FrrFrr" class="outro__img" />
    </div>
    <div class="outro__animated outro__animated--rotating outro__lighting">
      <img src="../../assets/img/animated/lighting.png" alt="FrrFrr" class="outro__img" />
    </div>
    <div class="outro__animated outro__animated--rotating outro__heart">
      <img src="../../assets/img/animated/heart.png" alt="FrrFrr" class="outro__img" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'FrrOutro',
};
</script>

<style scoped lang="sass" src="./FrrOutro.sass"></style>
