<template>
  <div class="how">
    <h2 class="how__title">Как получить пушистого тиктокера?</h2>
    <div class="how__steps">
      <div class="how__step" v-for="(step, index) of steps" :key="index">
        <div class="how__index">
          {{ index + 1 }}
        </div>
        <div class="how__text">
          {{ step }}
        </div>
      </div>
    </div>
    <div class="how__button"><FrrButton :isStreched="true" @click="handleBtnClick" /></div>
  </div>
</template>

<script>
import FrrButton from '@/components/FrrButton/FrrButton.vue';
import { eventBus, customEvents } from '@/event-bus';

export default {
  name: 'FrrHowTo',
  components: { FrrButton },
  data: () => ({
    steps: [
      'Бупайте на кнопку «Хочу котика!»',
      'Заполните свои контактные данные',
      'Вам придёт электронное письмо с подробной анкетой с адреса frrfrr.home@9pandas.ru. Анкету необходимо детально заполнить и отправить обратно',
      'Если вы подходите котику, мы подробно расскажем вам, как можно забрать его домой. Если нет, то объясним почему.',
      'Чтобы увезти котейку домой, с собой нужно иметь переноску и паспорт',
      'И помните: если котик не прижился или вы передумали, обратитесь к нам. У вас всегда есть возможность вернуть его обратно.',
    ],
  }),
  methods: {
    handleBtnClick() {
      eventBus.$emit(customEvents.SHOW_MODAL, {});
    },
  },
};
</script>

<style scoped lang="sass">
.how
  width: 100%
  padding: 0 20px
  font-family: $font-family-roca
  font-size: 16px
  font-weight: normal
  line-height: 1.2em
  color: #FFE3FC

.how__title
  margin: 0
  margin-bottom: 35px
  font-size: 24px
  font-weight: bold
  text-align: center

.how__step
  max-width: 530px
  margin: 0 auto
  display: flex
  align-items: center
  margin-bottom: 25px

  &:last-child
    margin-bottom: 40px

.how__index
  display: flex
  flex-shrink: 0
  justify-content: center
  align-items: center
  width: 36px
  height: 36px
  margin-right: 20px
  font-size: 18px
  line-height: 1em
  color: $bg-color-green
  border: 2px solid $bg-color-green
  border-radius: 50%

  +min-w($mobile)
    margin-right: 40px

.how__button
  margin: 0 auto
  max-width: 328px
</style>
