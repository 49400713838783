<template>
  <div class="intro">
    <div class="intro__content">
      <!-- основной контент -->

      <div class="intro__partners">
        <img src="../../assets/img/logos/partners-logo.svg" alt="cosmo and 9 pandas" class="intro__partners-logo" />
      </div>

      <div class="intro__logo logo">
        <img src="../../assets/img/logos/logo.png" alt="logo" class="logo__img" />
      </div>

      <div class="intro__title" v-if="isHome">
        <FrrDescription>
          <div class="intro__description">
            Первый социально-развлекательный, позитивно-увеселительный, кошачьи-умилительный TikTok House, из которого
            бездомные котики уезжают прямо в семью.
          </div>
        </FrrDescription>
      </div>

      <!-- анимации -->

      <div class="intro__lasers-animated intro__animated intro__animated--color">
        <img src="../../assets/img/animated/lasers.png" alt="" class="intro__lasers intro__autowidth" />
      </div>

      <div class="intro__scratching-animated intro__animated intro__animated--translate">
        <img src="../../assets/img/animated/rays.png" alt="rays" class="intro__rays intro__animated--hide-slow" />
        <img src="../../assets/img/animated/scratching.png" alt="scratching" class="intro__scratching" />
      </div>

      <div class="intro__shine-animated intro__shine-animated--left intro__animated intro__animated--color">
        <img
          src="../../assets/img/animated/shine-violet.png"
          alt="shine"
          class="intro__shine intro__shine--violet intro__autowidth"
        />
      </div>

      <div class="intro__shine-animated intro__shine-animated--right intro__animated intro__animated--color">
        <img
          src="../../assets/img/animated/shine-green.png"
          alt="shine"
          class="intro__shine intro__shine--green intro__autowidth"
        />
      </div>

      <div class="intro__crecers-animated intro__crecers-animated--left intro__animated intro__animated--rotating-low">
        <img
          src="../../assets/img/animated/crecer-small.png"
          alt="crecer"
          class="intro__crecer intro__crecer--small-reverse intro__autowidth"
        />
      </div>

      <div
        class="
          intro__crecers-animated intro__crecers-animated--right intro__animated intro__animated--rotating-low-reverse
        "
      >
        <img src="../../assets/img/animated/crecer.png" alt="crecer" class="intro__crecer intro__autowidth" />
      </div>

      <div class="intro__hearts-animated intro__animated intro__animated--rotating">
        <img src="../../assets/img/animated/heart.png" alt="heart" class="intro__heart intro__heart--small" />
        <img src="../../assets/img/animated/heart.png" alt="heart" class="intro__heart intro__heart--large" />
      </div>

      <div class="intro__lighting-animated intro__animated intro__animated--hide-liting">
        <img src="../../assets/img/animated/lighting.png" alt="lighting" class="intro__lighting intro__autowidth" />
      </div>

      <div class="intro__stars-animated intro__animated intro__animated--hide-fast">
        <img src="../../assets/img/animated/star.png" alt="star" class="intro__star intro__star--small" />
        <img src="../../assets/img/animated/star.png" alt="star" class="intro__star intro__star--large" />
      </div>
    </div>

    <div class="intro__social" v-if="isHome">
      <FrrRoundBtn type="tiktok" size="small" href="https://www.tiktok.com/@frrfrrhouse" />
    </div>
  </div>
</template>

<script>
import FrrRoundBtn from '@/components/FrrRoundBtn/FrrRoundBtn.vue';
import FrrDescription from '@/components/FrrDescription/FrrDescription.vue';

export default {
  name: 'FrrIntro',
  components: { FrrRoundBtn, FrrDescription },
  data: () => ({
    isHome: true,
  }),
  mounted() {
    this.isHome = this.$router.currentRoute.name === 'home';
  },
};
</script>

<style lang="sass" scoped src="./FrrIntro.sass"></style>
