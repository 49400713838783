<template>
  <div :class="['ticker', 'ticker--' + position]">
    <div :class="['ticker__icon', 'ticker__icon--' + position]"></div>
    <div :class="['ticker__text', 'ticker__text--' + position]" ref="wrapperRef">
      <marquee-text :repeat="2" :duration="12" :reverse="isReverse" v-if="position === 'top' || position === 'bottom'">
        <div class="ticker__advertising" v-for="(text, index) of advertisingText" :key="index">
          {{ text }}
        </div>
      </marquee-text>
      <div :class="['ticker__content', computedContentClasses]" v-else>
        <div
          :class="['ticker__advertising ticker__advertising--animated', computedTextClasses]"
          v-for="i of 10"
          :key="i"
        >
          <div class="ticker__advertising-text" v-for="(text, index) of advertisingText" :key="index">{{ text }}</div>
        </div>
      </div>
    </div>
    <div :class="['ticker__icon', 'ticker__icon--' + position]" v-if="position === 'bottom'"></div>
  </div>
</template>

<script>
import MarqueeText from 'vue-marquee-text-component';

export default {
  name: 'FrrTicker',
  components: { MarqueeText },
  data() {
    return {
      isIOs: false,
    };
  },
  props: {
    position: {
      type: String,
      validator: (position) => ['top', 'left', 'right', 'bottom'].includes(position),
      default: 'top',
    },
    advertisingText: {
      type: Array,
      default: () => [
        'Здесь могла бы быть ваша реклама',
        'А пока тут текст, который набирали кошки',
        '321iul####jn28238y1iuib1dik1§§',
      ],
    },
  },
  computed: {
    isReverse() {
      return this.position === 'bottom';
    },
    computedTextClasses() {
      return {
        'ticker__advertising--animated-reverse': this.position === 'right',
        'ticker__advertising--ios': this.isIOs,
      };
    },
    computedContentClasses() {
      return { 'ticker__content--ios': this.isIOs };
    },
  },
  methods: {
    setEmojiFromIndex(index) {
      return require((+index + 3) % 2 ? '@/assets/img/icons/cat-emoji.svg' : '@/assets/img/icons/foot-emoji.svg');
    },
    detectIos() {
      this.isIOs = this.$device.isIOS;
    },
  },
  mounted() {
    this.detectIos();
  },
};
</script>

<style lang="sass" scoped src="./FrrTicker.sass"></style>
