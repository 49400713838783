const PROFILES_URL = process.env.BASE_URL + 'api/get_profiles';
const MESSAGE_URL = process.env.BASE_URL + 'api/cat_applications';
const APPLICATION_URL = process.env.BASE_URL + 'api/cat_applications';

const DEFAULT_OPTIONS = {
  method: 'GET',
  mode: 'cors',
  headers: { 'Content-Type': 'application/json' },
};

const setError = (response) => `server responsed with status ${response.status} and status text ${response.statusText}`;

export const getProfiles = () => {
  const options = DEFAULT_OPTIONS;

  return fetch(PROFILES_URL, options).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      throw new Error(setError(response));
    }
  });
};

export const sendRequest = (data) => {
  const options = {
    ...DEFAULT_OPTIONS,
    method: 'POST',
    body: JSON.stringify(data),
  };

  return fetch(MESSAGE_URL, options)
    .then((response) => response.json())
    .catch((error) => {
      throw new Error(error);
    });
};

export const getApplicationHashStatus = (hash) => {
  const options = DEFAULT_OPTIONS;

  return fetch(`${MESSAGE_URL}/${hash}`, options).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      throw new Error(setError(response));
    }
  });
};

export const sendApplicationRequest = (data, hash) => {
  const options = {
    ...DEFAULT_OPTIONS,
    method: 'POST',
    body: JSON.stringify(data),
  };

  return fetch(`${APPLICATION_URL}/${hash}/confirm`, options).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      throw new Error(setError(response));
    }
  });
};
