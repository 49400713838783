<template>
  <div class="stream">
    <div class="stream__container">
      <div class="stream__ref-wrapper">
        <div class="stream__slider">
          <div class="stream__swiper-container">
            <div class="stream__video">
              <div class="stream__wrapper">
                <div class="stream__video-holder">
                  <!-- <img src="../../assets/img/stream/stub.jpg" alt="котики скоро вернутся" class="stream__stub" /> -->
                  <iframe
                    class="stream__player"
                    ref="palyerRef"
                    src="https://vk.com/video_ext.php?oid=-211176447&id=456239112&hash=01ec7ad8844cfefd&hd=2"
                    allow="autoplay; encrypted-media; fullscreen; picture-in-picture;"
                    frameborder="0"
                    allowfullscreen
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="stream__footer">
        <div class="stream__about">
          <FrrDescription>
            <div class="stream__about-text">
              Каждый день пушистые тиктокеры будут радовать вас КОТОстрофическим количеством контента. Ежедневно
              в&nbsp;TikTok, круглосуточно в&nbsp;прямой трансляции и&nbsp;навсегда в&nbsp;ваших сердечках.
            </div>
          </FrrDescription>
        </div>
      </div>
    </div>
    <div class="stream__social">
      <FrrRoundBtn type="tiktok" size="normal" href="https://www.tiktok.com/@frrfrrhouse" />
    </div>
    <div class="stream__animation">
      <img src="../../assets/img/animated/bubble.png" alt="frrfrr" class="stream__bubble-img" />
      <img src="../../assets/img/animated/bubble.png" alt="frrfrr" class="stream__bubble-img stream__bubble-img--sm" />
      <img src="../../assets/img/animated/crecer-stream.png" alt="frrfrr" class="stream__crecer-img" />
    </div>
    <div class="stream__fixed-video f-video" v-show="showFixedVideo" ref="fixedVideoRef">
      <div class="f-video__close" @click="closeVideo"></div>
      <div class="f-video__stream-holder">
        <iframe
          class="f-video__stream"
          src="https://vk.com/video_ext.php?oid=-211176447&id=456239112&hash=01ec7ad8844cfefd&hd=2"
          allow="autoplay; encrypted-media; fullscreen; picture-in-picture;"
          frameborder="0"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
import FrrRoundBtn from '@/components/FrrRoundBtn/FrrRoundBtn.vue';
import FrrDescription from '@/components/FrrDescription/FrrDescription.vue';
import { eventBus, customEvents } from '@/event-bus';

export default {
  name: 'FrrStreamVk',
  components: {
    FrrRoundBtn,
    FrrDescription,
  },
  data: () => ({
    showFixedVideo: false,
    shouldFixedVideoBeHidden: false,
    isFixedVideoStarted: false,
    fixedPlayer: null,
    isMobile: false,
    isFullScreen: false,
    shouldDisplayPauseBtn: false,
  }),

  mounted() {
    this.handleShowFixedVideo();
    this.subOnWindowEvents();
    this.handleCustomEvents();
  },

  beforeDestroy() {
    this.unsubOnWindowEvents();
  },

  methods: {
    handleCustomEvents() {
      eventBus.$on(customEvents.FOOTER_IN_VUEWPORT, (delta) => {
        if (!this.$refs.fixedVideoRef) return;
        this.$refs.fixedVideoRef.style.bottom = delta + 20 + 'px';
      });
    },

    subOnWindowEvents() {
      window.addEventListener('scroll', this.handleShowFixedVideo);
      window.addEventListener('fullscreenchange', this.handleFullscreenChange);
      this.setMobileDevice();
      window.addEventListener('resize', this.handleFixedPlayerOnResize);
    },

    unsubOnWindowEvents() {
      window.removeEventListener('scroll', this.handleShowFixedVideo);
      window.removeEventListener('fullscreenchange', this.handleFullscreenChange);
      window.removeEventListener('resize', this.handleFixedPlayerOnResize);
    },

    setMobileDevice() {
      this.isMobile = window.innerWidth < 1024;
    },

    handleFixedPlayerOnResize() {
      this.setMobileDevice();
    },

    handleShowFixedVideo() {
      if (!this.$refs.palyerRef) return;
      const { top } = this.$refs.palyerRef.getBoundingClientRect();
      if (this.shouldFixedVideoBeHidden) {
        this.shouldFixedVideoBeHidden = top < 0;
      } else {
        this.showFixedVideo = top < 0 && this.$refs.palyerRef.clientHeight + top - 25 < 0;
        this.shouldFixedVideoBeHidden = false;
        eventBus.$emit(customEvents.TOGGLE_FIXED_VIDEO_VISIBILITY, this.showFixedVideo);
      }
    },

    closeVideo() {
      this.showFixedVideo = false;
      this.shouldFixedVideoBeHidden = true;
      eventBus.$emit(customEvents.TOGGLE_FIXED_VIDEO_VISIBILITY, this.showFixedVideo);
    },
  },
};
</script>

<style lang="sass">
.play-wrapper
  display: none

  .fullscreen &
    display: flex
    justify-content: center
    align-items: center

    &::before
      content: ''
      display: block
      width: 56px
      height: 56px
      border-radius: 50%
      border: 3px solid #C5FF71
      background: url('../../assets/img/icons/play.svg') 19px center no-repeat, #6932FF
      transition: all 200ms linear
      box-shadow: 0px 0px 15px #c5ff70
      cursor: pointer

    svg
      display: none !important
</style>

<style scoped lang="sass" src="./FrrStreamVk.sass"></style>
