<template>
  <div class="form">
    <ValidationObserver v-slot="{ handleSubmit }" tag="div" v-if="!requestSubmited && !requestRejected && !serverError">
      <form @submit.prevent="handleSubmit(onSubmit)" novalidate class="form__form">
        <div class="form__header">Заполните заявку, и мы свяжемся с вами</div>
        <div class="form__body">
          <ValidationProvider
            tag="div"
            class="form__field"
            name="Имя"
            rules="required"
            v-slot="{ errors }"
            mode="eager"
          >
            <label class="form__label" for="name">Как к вам обращаться?</label>
            <input
              :class="['form__input', { 'form__input--error': errors[0] }]"
              v-model="name"
              id="name"
              name="name"
              type="text"
              placeholder="Имя"
            />
            <span class="form__error">{{ errors[0] }}</span>
          </ValidationProvider>
          <ValidationProvider
            tag="div"
            class="form__field"
            name="E-mail"
            rules="required|email"
            v-slot="{ errors }"
            mode="eager"
          >
            <label class="form__label" for="email">Email для связи</label>
            <input
              :class="['form__input', { 'form__input--error': errors[0] }]"
              v-model="email"
              id="email"
              name="email"
              type="email"
              placeholder="Email"
            />
            <span class="form__error">{{ errors[0] }}</span>
          </ValidationProvider>

          <div class="form__field form__dropdown">
            <label class="form__label" for="email">Выбранный котик</label>
            <div class="form__input dropdown">
              <div
                :class="['dropdown__title', { 'dropdown__title--open': isDropdownOpen }]"
                @click="setDropdownOpenFlag(!isDropdownOpen)"
              >
                {{ selectedProfileName }}
              </div>
              <div class="dropdown__body" v-if="isDropdownOpen">
                <div
                  class="dropdown__item"
                  v-for="profile in profiles"
                  :key="profile.id"
                  @click="selectProfile(profile)"
                >
                  {{ profile.name }}
                </div>
              </div>
            </div>
          </div>

          <div class="form__footer">
            <div class="form__checkbox">
              <input type="checkbox" v-model="policyChecked" id="agreement" class="form__agreement" />
              <label for="agreement" class="form__checkbox-label">Все понятно</label>
            </div>
            <div class="form__submit">
              <FrrButton class="form__btn" :disabled="!policyChecked" :isStreched="true" :loading="formPending" />
            </div>
          </div>

          <div class="form__policy">
            Заполнение заявки на данном сайте не требует предоставления персональных данных (таких как: ФИО полностью,
            адрес проживания, сведения о семейном положении, электронная почта, содержащая в своем адресе персональные
            данные), в связи с чем при заполнении заявки вы обязуетесь не вводить никаких ваших персональных данных. В
            пункте “Как к вам обращаться” допускается указывать вымышленное имя, не являющееся вашим реальным именем.
          </div>
        </div>
      </form>
    </ValidationObserver>

    <div class="form__submited" v-if="requestSubmited && !serverError">
      <div class="form__img-holder">
        <img :src="pickedProfile.image_url" :alt="pickedProfile.name" class="form__img" />
      </div>
      <h2 class="form__submit-heading">Ты молодец!</h2>
      <p class="form__submit-text">Мы с котиком скоро тебе напишем на твою почту!</p>
      <div class="form__succes-bg form__succes-bg--right">
        <img src="../../assets/img/banners/banner-stars.png" alt="frrfrr" class="form__image" />
      </div>
      <div class="form__succes-bg form__succes-bg--left">
        <img src="../../assets/img/banners/banner-stars.png" alt="frrfrr" class="form__image" />
      </div>
    </div>

    <div class="form__rejected" v-if="requestRejected && !serverError">
      <h2 class="form__reject-heading">Вы ранее подали заявку на котика</h2>
      <p class="form__reject-text">
        Проверьте почтовый ящик <br />
        {{ email }},<br />
        мы выслали вам описание дальнейших шагов
      </p>
    </div>

    <div class="form__rejected" v-if="serverError">
      <h2 class="form__reject-heading">Что-то пошло не так.</h2>
      <p class="form__reject-text">
        Попробуйте отправить заявку позже или <br />
        напишите нам на почту <a href="mailto:frrfrr.home@9pandas.ru" class="form__mailto">frrfrr.home@9pandas.ru</a>
      </p>
      <div class="form__reject-btn">
        <FrrButton :isStreched="true" text="Хорошо" @click="close" />
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver, localize, extend } from 'vee-validate/dist/vee-validate';
import { required, email } from 'vee-validate/dist/rules';
import ru from 'vee-validate/dist/locale/ru.json';
import FrrButton from '../FrrButton/FrrButton';
import { sendRequest } from '../../api';
import { eventBus, customEvents } from '@/event-bus';
localize('ru', ru);
extend('email', email);
extend('required', required);

export default {
  name: 'FrrForm',
  components: {
    FrrButton,
    ValidationProvider,
    ValidationObserver,
  },
  data: () => ({
    email: '',
    name: '',
    pickedProfile: null,
    policyChecked: false,
    isDropdownOpen: false,
    formPending: false,
    requestSubmited: false,
    requestRejected: false,
    serverError: false,
  }),
  props: {
    profiles: {
      type: Array,
      default: () => [],
    },
    selectedProfile: {
      type: Object,
    },
  },
  mounted() {
    this.pickedProfile = this.selectedProfile;
  },
  computed: {
    selectedProfileName() {
      return this.pickedProfile?.name || 'Имя котика';
    },
  },
  methods: {
    onSubmit() {
      if (!this.email || !this.pickedProfile?.name || this.formPending) return;

      this.formPending = true;
      const body = {
        name: this.name,
        email: this.email,
        profile_id: this.pickedProfile.id,
      };
      sendRequest(body)
        .then((result) => {
          if (result.status && String(result.status).startsWith('20')) {
            this.requestSubmited = true;
          } else {
            this.requestRejected = true;
          }
        })
        .catch(() => (this.serverError = true))
        .finally(() => (this.formPending = false));
    },

    setDropdownOpenFlag(flag) {
      this.isDropdownOpen = flag;
    },

    selectProfile(profile) {
      this.pickedProfile = profile;
      this.setDropdownOpenFlag(false);
    },

    close() {
      eventBus.$emit(customEvents.HIDE_MODAL);
    },
  },
};
</script>

<style scoped lang="sass" src="./FrrForm.sass"></style>
