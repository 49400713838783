<template>
  <div class="profiles">
    <div class="profiles__social">
      <FrrRoundBtn type="tiktok" size="normal" href="https://www.tiktok.com/@frrfrrhouse" />
    </div>

    <div class="profiles__action">
      <button type="button" class="profiles__action-btn" @click="handleClick(null)">
        <span class="profiles__btn-heading"> Жми сюда, </span>
        чтобы узнать, как&nbsp;стать хозяином пушистого тиктокера
      </button>
    </div>

    <div class="profiles__scratching">
      <img src="../../assets/img/animated/rays-sm.png" alt="frrfrr" class="profiles__rays-img" />
      <img src="../../assets/img/animated/scratching-sm.png" alt="frrfrr" class="profiles__scratch-img" />
    </div>

    <div class="profiles__slider" v-if="profiles.length">
      <swiper
        ref="swiperRef"
        :options="swiperOptions"
        :key="swiperKey"
        @slideNextTransitionEnd="handleSlideNext"
        @slidePrevTransitionEnd="handleSlidePrev"
      >
        <swiper-slide v-for="profile of profiles" :key="profile.id">
          <div class="profiles__slide p-slide">
            <div class="p-slide__image">
              <div class="p-slide__img-holder">
                <img :src="profile.image_url" :alt="profile.name" class="p-slide__img" />
              </div>
            </div>
            <div class="p-slide__text">
              <div class="p-slide__info">
                <strong class="p-slide__name">{{ profile.name }}</strong>
                <div class="p-slide__age">{{ profile.age | yearCases }} / {{ profile.weight }} кг</div>
                <div class="p-slide__description">
                  {{ profile.description }}
                </div>
              </div>
              <div class="p-slide__btn">
                <FrrButton @click="handleClick(profile)" />
              </div>
            </div>
          </div>
        </swiper-slide>
        <div class="profiles__arrow profiles__arrow--next" slot="button-next">
          <FrrSliderArrow size="small" />
        </div>
        <div class="profiles__arrow profiles__arrow--prev" slot="button-prev">
          <FrrSliderArrow direction="prev" size="small" />
        </div>
        <div class="profiles__pagination" slot="pagination"></div>
      </swiper>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import FrrButton from '@/components/FrrButton/FrrButton.vue';
import FrrRoundBtn from '@/components/FrrRoundBtn/FrrRoundBtn.vue';
import FrrSliderArrow from '@/components/FrrSliderArrow/FrrSliderArrow.vue';
import { eventBus, customEvents } from '@/event-bus';

export default {
  name: 'FrrProfiles',
  components: { Swiper, SwiperSlide, FrrButton, FrrRoundBtn, FrrSliderArrow },
  filters: {
    yearCases(amount) {
      switch (amount) {
        case 1:
          return amount + ' год';

        case 2:
        case 3:
        case 4:
          return amount + ' года';

        default:
          return amount + ' лет';
      }
    },
  },
  data: () => ({
    profiles: [],
    swiperSpeed: 200,

    swiperKey: Math.random(),
    swiperOptions: {
      slidesPerView: 1,
      autoplay: false,
      loop: true,
      centeredSlides: true,
      pagination: {
        el: '.profiles__pagination',
        clickable: true,
      },
      navigation: {
        nextEl: '.profiles__arrow--next',
        prevEl: '.profiles__arrow--prev',
      },
    },
  }),
  computed: {
    activeIndex() {
      return this.$refs.swiperRef.$swiper.activeIndex;
    },
  },
  created() {
    eventBus.$on(customEvents.PROFILES_LOADED, (data) => (this.profiles = data));
  },
  methods: {
    handleSlideNext() {
      if (this.$refs.swiperRef.$swiper.realIndex === 0) {
        this.reinitSwiperKey();
      }
    },

    handleSlidePrev() {
      if (this.$refs.swiperRef.$swiper.realIndex === this.profiles.length - 1) {
        this.reinitSwiperKey(true);
      }
    },

    reinitSwiperKey(reverse = false) {
      this.swiperKey = Math.random();
      if (reverse) {
        this.$nextTick(() => {
          this.$refs.swiperRef.$swiper.slideTo(this.profiles.length, 0);
        });
      }
    },

    handleClick(profile) {
      eventBus.$emit(customEvents.SHOW_MODAL, profile);
    },
  },
};
</script>

<style scoped lang="sass" src="./FrrProfiles.sass"></style>
