<template>
  <button type="button" class="scroll-top" tabindex="-1">
    <svg width="28" height="26" viewBox="0 0 28 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.1353 22.7387L15.3097 26L28 13.0129L15.3343 0L12.1538 3.25498L19.3936 10.6932H0V15.3009H19.403L12.1353 22.7387Z"
        fill="#C5FF71"
      />
    </svg>
  </button>
</template>

<script>
export default {
  name: 'FrrScrollTop',
};
</script>

<style lang="sass" scoped>
.scroll-top
  display: flex
  justify-content: center
  align-items: center
  width: 51px
  height: 51px
  padding: 0
  cursor: pointer
  border: 3px solid $bg-color-green
  border-radius: 50%
  background-color: $bg-color-violet
  transition: background-color 200ms linear, border-col 200ms linear, background-color 200ms linear

  path
    transform-origin: center center
    transition: fill 200ms linear, transform 200ms linear

  svg
    width: 22px
    height: 24px
    transform: rotate(-90deg)

  &:hover
    background-color: $bg-color-green
    border-color: $bg-color-violet
    box-shadow: 0px 0px 12px #F291D3

    path
      fill: $bg-color-violet

  &:active
    background-color: $bg-color-violet
    border-color: $bg-color-green
    box-shadow: inset 0px 4px 15px rgba(0, 0, 0, 0.35)

    path
      fill: $bg-color-green
      transform: scale(0.8)
</style>
