import Vue from 'vue';

export const eventBus = new Vue();

export const customEvents = Object.freeze({
  SHOW_MODAL: 'show-modal',
  HIDE_MODAL: 'hide-modal',
  PROFILES_LOADED: 'profiles-loaded',
  TOGGLE_FIXED_VIDEO_VISIBILITY: 'toggle-fixed-video-visibility',
  FOOTER_IN_VUEWPORT: 'footer-in-vuewport',
});
