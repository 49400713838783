<template>
  <div :class="['slider-arrow', `slider-arrow--${direction}`, { 'slider-arrow--small': isSmall }]"></div>
</template>

<script>
export default {
  name: 'FrrSliderArrow',
  props: {
    direction: {
      type: String,
      validator: (direction) => ['next', 'prev'].includes(direction),
      default: 'next',
    },
    size: {
      type: String,
      validator: (size) => ['normal', 'small'].includes(size),
      default: 'normal',
    },
  },
  computed: {
    isSmall() {
      return this.size === 'small';
    },
  },
};
</script>

<style scoped lang="sass" src="./FrrSliderArrow.sass"></style>
