<template>
  <div :class="['btn', { 'btn--disabled': disabled, 'btn--stretch': isStreched }]">
    <a
      :class="['btn__element', { 'btn__element--stretch': isStreched, 'btn__element--pending': loading }]"
      :disabled="disabled"
      v-if="href"
    >
      <span class="btn__text" v-if="!loading">
        {{ text || 'Хочу котика!' }}
      </span>
      <div class="btn__loading" v-else>
        <img src="../../assets/img/icons/spinner-icon.svg" class="btn__spinner btn__spinner--right" alt="spinner" />
        <img src="../../assets/img/icons/spinner-icon.svg" class="btn__spinner btn__spinner--left" alt="spinner" />
        Отправляем
      </div>
    </a>
    <button
      @click="handleClick"
      :class="['btn__element', { 'btn__element--stretch': isStreched, 'btn__element--pending': loading }]"
      :disabled="disabled"
      :type="type"
      v-else
    >
      <span class="btn__text" v-if="!loading">
        {{ text || 'Хочу котика!' }}
      </span>
      <div class="btn__loading" v-else>
        <img src="../../assets/img/icons/spinner-icon.svg" class="btn__spinner btn__spinner--right" alt="spinner" />
        <img src="../../assets/img/icons/spinner-icon.svg" class="btn__spinner btn__spinner--left" alt="spinner" />
        Отправляем
      </div>
    </button>
  </div>
</template>

<script>
export default {
  name: 'FrrButton',
  props: {
    href: String,
    disabled: Boolean,
    text: String,
    isStreched: Boolean,
    type: {
      type: String,
      default: 'submit',
    },
    loading: Boolean,
  },
  methods: {
    handleClick() {
      this.$emit('click');
    },
  },
};
</script>

<style lang="sass" scoped src="./FrrButton.sass"></style>
