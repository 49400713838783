<template>
  <div :class="['button', 'button--' + size, { 'button--disabled': disabled }]">
    <a class="button__link" target="_blank" :href="href" v-if="href">
      <div class="button__image">
        <FrrIcon :iconName="type" />
      </div>
    </a>
    <div class="button__link" v-else>
      <div :class="['button__image', 'button__image--' + type]">
        <FrrIcon :iconName="type" />
      </div>
    </div>
  </div>
</template>

<script>
import FrrIcon from '@/components/FrrIcon/FrrIcon.vue';

export default {
  name: 'FrrRoundBtn',
  components: { FrrIcon },
  props: {
    type: {
      type: String,
      required: true,
      validator: (value) => ['tiktok', 'play', 'help', 'pause'].includes(value),
    },
    size: {
      type: String,
      validator: (value) => ['normal', 'large', 'small'].includes(value),
      default: 'normal',
    },
    href: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style scoped lang="sass" src="./FrrRoundBtn.sass"></style>
