<template>
  <footer class="footer">
    <div class="footer__email">
      <p class="footer__text">Почта для сотрудничества:</p>
      <a href="mailto:frrfrr.pr@9pandas.ru" class="footer__link"> frrfrr.pr@9pandas.ru </a>
    </div>
    <div class="footer__social">
      <a class="footer__sn-icon footer__sn-icon--tiktok" href="https://www.tiktok.com/@frrfrrhouse" target="_blank">
        <img src="../../assets/img/footer/tiktok-footer.svg" alt="tiktok" class="footer__icon" />
      </a>
      <a class="footer__sn-icon footer__sn-icon--vk" href="https://vk.com/frrfrrhouse" target="_blank">
        <img src="../../assets/img/footer/vk-footer.svg" alt="vk" class="footer__icon" />
      </a>
      <a class="footer__sn-icon footer__sn-icon--twich" href="https://www.twitch.tv/frrfrr_house" target="_blank">
        <img src="../../assets/img/footer/twich-footer.svg" alt="twich" class="footer__icon" />
      </a>
    </div>
    <div class="footer__partners">
      <div class="footer__partner-icons">
        <!-- <a class="footer__p-icon footer__p-icon--cosmo" href="https://www.cosmo.ru/" target="_blank">
          <img src="../../assets/img/footer/cosmo-footer.svg" alt="cosmo" class="footer__icon" />
        </a> -->
        <a class="footer__p-icon footer__p-icon--nine" href="https://9pandas.ru/" target="_blank">
          <img src="../../assets/img/footer/nine-pandas-footer.svg" alt="9 pandas" class="footer__icon" />
        </a>
        <a class="footer__p-icon footer__p-icon--wood" href="https://lesnoj-prijut.ru/" target="_blank">
          <img src="../../assets/img/footer/wood-footer.svg" alt="Лесной приют" class="footer__icon" />
        </a>
      </div>
      <div class="footer__address">@ {{ year }} ООО «9 ПАНД»</div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'FrrFooter',
  data: () => ({
    year: new Date().getFullYear(),
  }),
};
</script>

<style scoped lang="sass" src="./FrrFooter.sass"></style>
